<template>
  <div>
    <Navbar @finished="navLoaded = true" />
    <NuxtPage />
    <Footer />
    <CookieBanner />
    <CSSEditorPanel v-if="navLoaded && $config.public.devMode && isCreativeMode()" />
    <ThemePicker v-if="navLoaded && $config.public.devMode && isCreativeMode()" />
  </div>
</template>
<script setup lang="ts">
const navLoaded = ref(false)
</script>
<style>
html, body {
  margin: 0;
  padding: 0;
}
</style>
