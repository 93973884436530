import { defineStore } from 'pinia'

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useStatesStore = defineStore('states', () => {
  const componentSelected = ref<string>('')
  const editPanelShowing = ref(false)
  const transparentEffect = ref(false)
  const isEditPanelShowing = computed(() => editPanelShowing.value)
  const componentSelectionClasses = computed(() => {
    return isCreativeMode() && ((editPanelShowing.value && !componentSelected.value) || (!editPanelShowing.value && componentSelected.value) || (!editPanelShowing.value && !componentSelected.value))
  })

  const createModeAndEditPanelShowing = (componentName?: string) => computed(() => isCreativeMode() && componentSelected.value !== componentName)
  function setComponentSelected (component: string) {
    componentSelected.value = component
  }
  function setTransparentEffect (val: boolean) {
    transparentEffect.value = val
  }
  function toggleEditPanel () {
    editPanelShowing.value = !editPanelShowing.value
    if (!editPanelShowing.value) { componentSelected.value = '' }
  }
  watch(
    () => componentSelected.value,
    (val) => {
      if (val && !editPanelShowing.value) {
        editPanelShowing.value = true
      }
    }
  )

  return { componentSelected, editPanelShowing, transparentEffect, setTransparentEffect, isEditPanelShowing, componentSelectionClasses, createModeAndEditPanelShowing, toggleEditPanel, setComponentSelected }
})
